import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { jsPDF } from 'jspdf';
import html2pdf from 'html2pdf.js';


const IsyeriTeklif = () => {
  const { control, handleSubmit, watch } = useForm();

  const questions = {
    genelBilgiler: [
      { name: 'tcVergiNo', label: 'TC/Vergi No', type: 'text' },
      { name: 'yetkiliKisi', label: 'Yetkili Kişi', type: 'text' },
      { name: 'yetkiliTel', label: 'Yetkili Kişi Tel No', type: 'text' },
      { name: 'faaliyetKonusu', label: 'Faaliyet Konusu', type: 'text' },
      { name: 'acikAdres', label: 'Açık Adres', type: 'text' },
      { name: 'isyeriDurum', label: 'İşyeri Mülkü Kendinin mi ?', type: 'radio' },
      { 
        name: 'isyeriDurumAciklama', 
        label: 'İşyeri Durumu Açıklama', 
        type: 'text', 
        dependency: 'isyeriDurum', 
        // burada showIf'i kaldırmıştık, bu yüzden hemen görünüyordu
      },      { name: 'metrekare', label: 'İşyeri Metrekaresi', type: 'number' },
      { name: 'konutVarMi', label: 'İşyeri Üzerinde Konut Var mı?', type: 'radio' },
      { name: 'bodrumVarMi', label: 'İşyerinin Bodrumu Var mı?', type: 'radio' },
      { name: 'binaKat', label: 'Bina Kaç Katlı', type: 'number' },
      { name: 'binaYas', label: 'Binanın Ortalama Yaşı', type: 'number' },
      { name: 'selRiski', label: 'İşyerini Sel Basma Riski Var mı?', type: 'radio' },
      { name: 'calisanSayisi', label: 'Çalışan Sayısı', type: 'number' },
      { name: 'daskDurum', label: 'İşyeri DASK\'a Tabi mi?', type: 'radio' }
    ],
    guvenlikOnlemleri: [
      { name: 'alarmVarMi', label: 'Alarm Var mı?', type: 'radio' },
      { name: 'alarmAciklama', label: 'Alarm Açıklama', type: 'text', dependency: 'alarmVarMi', showIf: 'Evet' },
      { name: 'kameraVarMi', label: 'Kamera Var mı?', type: 'radio' },
      { name: 'kepenkVarMi', label: 'Kepenk Var mı?', type: 'radio' },
      { name: 'bekciVarMi', label: 'Bekçi Var mı?', type: 'radio' }
    ],
    yanginOnlemleri: [
      { name: 'yanginTupuVarMi', label: 'Yangın Tüpü Var mı?', type: 'radio' },
      { name: 'yanginTupuAciklama', label: 'Yangın Tüpü Açıklama', type: 'text', dependency: 'yanginTupuVarMi', showIf: 'Evet' },
      { name: 'dumaDedektoruVarMi', label: 'Duman Dedektörü Var mı?', type: 'radio' },
      { name: 'sprinklerVarMi', label: 'Sprinkler Var mı?', type: 'radio' },
      { name: 'yanginDolabiVarMi', label: 'Yangın Dolabı Var mı?', type: 'radio' },
      { name: 'dolabiSuBesleme', label: 'Yangın Dolabı Suyu Nereden Besleniyor?', type: 'text', dependency: 'yanginDolabiVarMi', showIf: 'Evet' },
      { name: 'yanginAlarmiVarMi', label: 'Yangın Alarmı Var mı?', type: 'radio' },
      { name: 'suDeposuVarMi', label: 'Su Deposu Var mı?', type: 'radio' },
      { name: 'suDeposuAciklama', label: 'Su Deposu Kaç Ton?', type: 'text', dependency: 'suDeposuVarMi', showIf: 'Evet' },
      { name: 'jeneratorVarMi', label: 'Jeneratör Var mı?', type: 'radio' },
      { name: 'jeneratorOtomatikMi', label: 'Elektrik Kesildiğinde Jeneratör Otomatik Devreye Giriyor mu?', type: 'radio', dependency: 'jeneratorVarMi', showIf: 'Evet' },
      { name: 'gunesEnerjisiVarMi', label: 'İşyeri Üzerinde Güneş Enerjisi Var mı?', type: 'radio' }
    ],
    teminatlar: [
      { name: 'binaTeminat', label: 'Bina Teminat Bedeli', type: 'number' },
      { name: 'dekarsoyanTeminat', label: 'Dekorasyon Teminat Bedeli', type: 'number' },
      { name: 'demirbasTeminat', label: 'Demirbaş Teminat Bedeli', type: 'number' },
      { name: 'emteaBedel', label: 'Emtea Bedeli', type: 'number' },
      { name: 'ucuncuSahisMal', label: '3. Şahıs Malı', type: 'number' },
      { name: 'makineBedel', label: 'Makine Bedeli', type: 'number' },
      { name: 'elektronikCihazBedel', label: 'Elektronik Cihaz Bedeli', type: 'number' },
      { name: 'makineKirilmasi', label: 'Makine Kırılması İsteniyor mu?', type: 'radio' },
      { name: 'elektronikCihazAriza', label: 'Elektronik Cihaz Arızası İsteniyor mu?', type: 'radio' },
      { name: 'isverenMaliMesuliyet', label: 'İşveren Mali Mesuliyet İsteniyor mu?', type: 'radio' },
      { name: 'isverenMaliMesuliyetAciklama', label: 'İşveren Mali Mesuliyet Açıklama', type: 'text', dependency: 'isverenMaliMesuliyet', showIf: 'Evet' },
      { name: 'yanginMaliMesuliyet', label: 'Yangın Mali Mesuliyet İsteniyor mu?', type: 'radio' },
      { name: 'yanginMaliMesuliyetAciklama', label: 'Yangın Mali Mesuliyet Açıklama', type: 'text', dependency: 'yanginMaliMesuliyet', showIf: 'Evet' },
      { name: 'depremTeminati', label: 'Deprem Teminatı İsteniyor mu?', type: 'radio' },
      { 
        name: 'depremTeminatiAciklama', 
        label: 'Muafiyet Oranı', 
        type: 'radio',  // text yerine radio yapıyoruz
        dependency: 'depremTeminati', 
        showIf: 'Evet',
        options: ['%2', '%5', '%10']  // yeni özellik ekliyoruz
      },
      { name: 'depremTeminatiAciklama', label: 'Deprem Teminatı Açıklama', type: 'text', dependency: 'depremTeminati', showIf: 'Evet' },
      { name: 'camTeminati', label: 'Cam Teminatı', type: 'number' },
      { name: 'izolasyonEksikligi', label: 'İzolasyon Eksikliği Teminatı', type: 'number' },
      { name: 'tenteVarMi', label: 'Tente Var mı?', type: 'radio' },
      { name: 'tenteAciklama', label: 'Tente Açıklama', type: 'text', dependency: 'tenteVarMi', showIf: 'Evet' },
      { name: 'reklamTabelaVarMi', label: 'Reklam Tabelası Var mı?', type: 'radio' },
      { name: 'reklamTabelaAciklama', label: 'Reklam Tabelası Açıklama', type: 'text', dependency: 'reklamTabelaVarMi', showIf: 'Evet' },
      { name: 'oncekiSigorta', label: 'Daha Önce Sigorta Yaptırdınız mı?', type: 'radio' },
      { name: 'oncekiSigortaAciklama', label: 'Önceki Sigorta Açıklama', type: 'text', dependency: 'oncekiSigorta', showIf: 'Evet' },
      { name: 'hasarVarMi', label: 'Şu Ana Kadar Hiç Hasar Oldu mu?', type: 'radio' },
      { name: 'hasarAciklama', label: 'Hasar Açıklama', type: 'text', dependency: 'hasarVarMi', showIf: 'Evet' },
      { name: 'hasarTeminatlar', label: 'Hasar Olduysa Hangi Teminatlardan Hasar Aldınız?', type: 'text', dependency: 'hasarVarMi', showIf: 'Evet' }
    ],
    notlar: [
      { name: 'notlar', label: 'Notlar', type: 'textarea' }
    ]
  };

  const QuestionInput = ({ label, name, type, dependency, showIf, options }) => {
    const watchedValue = dependency ? watch(dependency) : null;
    const isVisible = !dependency || 
        (showIf ? watchedValue === showIf : watchedValue);

    if (!isVisible) return null;

    return (
        <Controller
            name={name}
            control={control}
            render={({ field }) => (
                <div className="mb-4">
                    {/* label'a text-red-600 sınıfı ekliyoruz */}
                    <label className="block mb-2 text-red-600 font-medium">{label}</label>
                    
                    {type === 'radio' && !options ? (
                        <div className="space-x-4">
                            <label className="inline-flex items-center">
                                <input
                                    type="radio"
                                    value="Evet"
                                    checked={field.value === "Evet"}
                                    onChange={(e) => field.onChange(e.target.value)}
                                    className="mr-2"
                                />
                                <span className="text-gray-700">Evet</span>
                            </label>
                            <label className="inline-flex items-center">
                                <input
                                    type="radio"
                                    value="Hayır"
                                    checked={field.value === "Hayır"}
                                    onChange={(e) => field.onChange(e.target.value)}
                                    className="mr-2"
                                />
                                <span className="text-gray-700">Hayır</span>
                            </label>
                        </div>
                    ) : type === 'radio' && options ? (
                        <div className="space-x-4">
                            {options.map((option) => (
                                <label key={option} className="inline-flex items-center">
                                    <input
                                        type="radio"
                                        value={option}
                                        checked={field.value === option}
                                        onChange={(e) => field.onChange(e.target.value)}
                                        className="mr-2"
                                    />
                                    <span className="text-gray-700">{option}</span>
                                </label>
                            ))}
                        </div>
                    ) : type === 'textarea' ? (
                        <textarea
                            {...field}
                            className="w-full p-2 border rounded text-gray-700"
                            rows="3"
                        />
                    ) : (
                        <input
                            {...field}
                            type={type}
                            className="w-full p-2 border rounded text-gray-700"
                        />
                    )}
                </div>
            )}
        />
    );
};

// generatePDF fonksiyonunu şu şekilde değiştirin
const generatePDF = (data) => {
  const content = `
  <div style="font-family: Arial, sans-serif; padding: 25px;">
    <h1 style="text-align: center; font-size: 20px; margin-bottom: 25px;">İŞYERİ BİLGİ FORMU</h1>
    
    <div style="display: flex; justify-content: space-between;">
      <!-- Sol Kolon -->
      <div style="width: 48%;">
        <h2 style="font-size: 12px; font-weight: bold; margin-bottom: 12px;">GENEL BİLGİLER</h2>
        <div style="font-size: 13px; line-height: 1.5;">
          <p><span style="color: #dc2626;">TC/Vergi No</span>: ${data.tcVergiNo || ''}</p>
          <p><span style="color: #dc2626;">Yetkili Kişi</span>: ${data.yetkiliKisi || ''}</p>
          <p><span style="color: #dc2626;">Tel No</span>: ${data.yetkiliTel || ''}</p>
          <p><span style="color: #dc2626;">Faaliyet</span>: ${data.faaliyetKonusu || ''}</p>
          <p><span style="color: #dc2626;">Adres</span>: ${data.acikAdres || ''}</p>
          <p><span style="color: #dc2626;">İşyeri Durumu</span>: ${data.isyeriDurum || ''} ${data.isyeriDurumAciklama ? `(${data.isyeriDurumAciklama})` : ''}</p>
          <p><span style="color: #dc2626;">Metrekare</span>: ${data.metrekare || ''}</p>
          <p><span style="color: #dc2626;">Konut Var mı</span>: ${data.konutVarMi || ''}</p>
          <p><span style="color: #dc2626;">Bodrum Var mı</span>: ${data.bodrumVarMi || ''}</p>
          <p><span style="color: #dc2626;">Bina Kat</span>: ${data.binaKat || ''}</p>
          <p><span style="color: #dc2626;">Bina Yaşı</span>: ${data.binaYas || ''}</p>
          <p><span style="color: #dc2626;">Sel Riski</span>: ${data.selRiski || ''}</p>
          <p><span style="color: #dc2626;">Çalışan Sayısı</span>: ${data.calisanSayisi || ''}</p>
          <p><span style="color: #dc2626;">DASK</span>: ${data.daskDurum || ''}</p>
        </div>
        
        <h2 style="font-size: 12px; font-weight: bold; margin-top: 15px; margin-bottom: 10px;">GÜVENLİK ÖNLEMLERİ</h2>
        <div style="font-size: 13px; line-height: 1.5;">
          <p><span style="color: #dc2626;">Alarm</span>: ${data.alarmVarMi || ''} ${data.alarmVarMi === 'Evet' ? `(${data.alarmAciklama || ''})` : ''}</p>
          <p><span style="color: #dc2626;">Kamera</span>: ${data.kameraVarMi || ''}</p>
          <p><span style="color: #dc2626;">Kepenk</span>: ${data.kepenkVarMi || ''}</p>
          <p><span style="color: #dc2626;">Bekçi</span>: ${data.bekciVarMi || ''}</p>
        </div>
      </div>
      
      <!-- Sağ Kolon -->
      <div style="width: 48%;">
        <h2 style="font-size: 12px; font-weight: bold; margin-bottom: 10px;">YANGIN ÖNLEMLERİ</h2>
        <div style="font-size: 13px; line-height: 1.5;">
          <p><span style="color: #dc2626;">Yangın Tüpü</span>: ${data.yanginTupuVarMi || ''} ${data.yanginTupuVarMi === 'Evet' ? `(${data.yanginTupuAciklama || ''})` : ''}</p>
          <p><span style="color: #dc2626;">Duman Dedektörü</span>: ${data.dumaDedektoruVarMi || ''}</p>
          <p><span style="color: #dc2626;">Yangın Dolabı</span>: ${data.yanginDolabiVarMi || ''}</p>
          <p><span style="color: #dc2626;">Sprinkler</span>: ${data.sprinklerVarMi || ''}</p>
          <p><span style="color: #dc2626;">Yangın Alarmı</span>: ${data.yanginAlarmiVarMi || ''}</p>
          <p><span style="color: #dc2626;">Su Deposu</span>: ${data.suDeposuVarMi || ''} ${data.suDeposuVarMi === 'Evet' ? `(${data.suDeposuAciklama || ''} Ton)` : ''}</p>
          <p><span style="color: #dc2626;">Jeneratör</span>: ${data.jeneratorVarMi || ''}</p>
          <p><span style="color: #dc2626;">Güneş Enerjisi</span>: ${data.gunesEnerjisiVarMi || ''}</p>
        </div>
        
        <h2 style="font-size: 12px; font-weight: bold; margin-top: 15px; margin-bottom: 10px;">TEMİNATLAR</h2>
        <div style="font-size: 13px; line-height: 1.5;">
          <p><span style="color: #dc2626;">Bina</span>: ${data.binaTeminat || ''}</p>
          <p><span style="color: #dc2626;">Dekorasyon</span>: ${data.dekarsoyanTeminat || ''}</p>
          <p><span style="color: #dc2626;">Demirbaş</span>: ${data.demirbasTeminat || ''}</p>
          <p><span style="color: #dc2626;">Emtea</span>: ${data.emteaBedel || ''}</p>
          <p><span style="color: #dc2626;">3.Şahıs Malı</span>: ${data.ucuncuSahisMal || ''}</p>
          <p><span style="color: #dc2626;">Makine</span>: ${data.makineBedel || ''}</p>
          <p><span style="color: #dc2626;">Elektronik</span>: ${data.elektronikCihazBedel || ''}</p>
          <p><span style="color: #dc2626;">Makine Kırılması</span>: ${data.makineKirilmasi || ''}</p>
          <p><span style="color: #dc2626;">Elektronik Arıza</span>: ${data.elektronikCihazAriza || ''}</p>
          <p><span style="color: #dc2626;">İşveren MM</span>: ${data.isverenMaliMesuliyet || ''} ${data.isverenMaliMesuliyet === 'Evet' ? `(${data.isverenMaliMesuliyetAciklama || ''})` : ''}</p>
          <p><span style="color: #dc2626;">Yangın MM</span>: ${data.yanginMaliMesuliyet || ''} ${data.yanginMaliMesuliyet === 'Evet' ? `(${data.yanginMaliMesuliyetAciklama || ''})` : ''}</p>
          <p><span style="color: #dc2626;">Deprem</span>: ${data.depremTeminati || ''} ${data.depremTeminati === 'Evet' ? `(Muafiyet: ${data.depremTeminatiAciklama || ''})` : ''}</p>
          <p><span style="color: #dc2626;">Cam</span>: ${data.camTeminati || ''}</p>
          <p><span style="color: #dc2626;">İzolasyon</span>: ${data.izolasyonEksikligi || ''}</p>
          <p><span style="color: #dc2626;">Tente</span>: ${data.tenteVarMi || ''} ${data.tenteVarMi === 'Evet' ? `(${data.tenteAciklama || ''})` : ''}</p>
          <p><span style="color: #dc2626;">Reklam Tabelası</span>: ${data.reklamTabelaVarMi || ''} ${data.reklamTabelaVarMi === 'Evet' ? `(${data.reklamTabelaAciklama || ''})` : ''}</p>
        </div>
      </div>
    </div>
    
<!-- Alt Bölüm -->
<div style="margin-top: 20px;">
<h2 style="font-size: 12px; font-weight: bold; margin-bottom: 10px;">SİGORTA VE HASAR BİLGİLERİ</h2>
<div style="font-size: 10px; line-height: 1.5;">
  <p><span style="color: #dc2626;">Önceki Sigorta</span>: ${data.oncekiSigorta || ''} ${data.oncekiSigorta === 'Evet' ? `(${data.oncekiSigortaAciklama || ''})` : ''}</p>
  <p><span style="color: #dc2626;">Hasar Durumu</span>: ${data.hasarVarMi || ''} ${data.hasarVarMi === 'Evet' ? `
    <br><span style="color: #dc2626;">Açıklama</span>: ${data.hasarAciklama || ''}
    <br><span style="color: #dc2626;">Teminatlar</span>: ${data.hasarTeminatlar || ''}` : ''}
  </p>
</div>

<h2 style="font-size: 12px; font-weight: bold; margin-top: 15px; margin-bottom: 10px;">NOTLAR</h2>
<p style="font-size: 10px; line-height: 1.5;">${data.notlar || ''}</p>
</div>

<!-- İmza ve Logo -->
<div style="margin-top: 155px; margin-bottom: 48px; display: flex; justify-content: space-between; align-items: center;">
  <!-- Sol tarafta logo -->
  <div>
    <img src="/kobilogo.gif" style="width: 150px; height: auto; opacity: 0.8;" />
  </div>
  <!-- Sağ tarafta kaşe/imza -->
  <div style="text-align: center;">
    <p style="font-size: 10px; margin-bottom: 5px;">Kaşe/İmza</p>
    <div style="border: 1px solid black; width: 150px; height: 60px;"></div>
  </div>
</div>

  `;

  const opt = {
    margin: 10,
    filename: 'isyeri-bilgi.pdf',
    image: { type: 'jpeg', quality: 0.98 },
    html2canvas: { scale: 2 },
    jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
  };

  html2pdf().set(opt).from(content).save();
};

  return (
    <div className="max-w-4xl mx-auto p-6">
      <h1 className="text-2xl font-bold mb-6">İşyeri Bilgi Formu</h1>
      
      <form onSubmit={handleSubmit(generatePDF)}>
        {Object.entries(questions).map(([category, items]) => (
          <div key={category} className="mb-8">
            <h2 className="text-xl font-semibold mb-4">
              {category === 'genelBilgiler' ? 'Genel Bilgiler' :
               category === 'guvenlikOnlemleri' ? 'Güvenlik Önlemleri' :
               category === 'yanginOnlemleri' ? 'Yangın Önlemleri' :
               category === 'teminatlar' ? 'Teminatlar' : 'Notlar'}
            </h2>
            
            <div className="space-y-4 bg-white p-4 rounded shadow">
              {items.map((item) => (
                <QuestionInput
                  key={item.name}
                  {...item}
                />
              ))}
            </div>
          </div>
        ))}

        <button
          type="submit"
          className="bg-blue-500 text-white px-6 py-2 rounded hover:bg-blue-600"
        >
          PDF Oluştur
        </button>
      </form>
    </div>
  );
};

export default IsyeriTeklif;