import React from 'react';
import { Rocket, Target, Star } from 'lucide-react';

const YearBanner = () => {
  return (
    <div className="w-full max-w-4xl mx-auto mb-8">
      <div className="bg-gradient-to-r from-purple-600 via-blue-600 to-indigo-600 p-1 rounded-2xl shadow-xl">
        <div className="bg-white/95 backdrop-blur-lg rounded-xl p-8">
          <div className="flex items-center justify-center space-x-4">
            <Target className="text-purple-500 animate-spin-slow" size={28} />
            <h1 className="text-3xl font-bold bg-gradient-to-r from-purple-600 via-blue-600 to-indigo-600 bg-clip-text text-transparent">
              #2025Yolunda
            </h1>
            <Rocket className="text-blue-500 animate-bounce" size={28} />
          </div>
          
          <p className="mt-4 text-center text-gray-600 font-medium">
            Hedeflerimize giden yolda her adım değerli...
          </p>
          
          <div className="mt-6 flex justify-center items-center space-x-3">
            {[...Array(3)].map((_, i) => (
              <div key={i} className="flex items-center space-x-1">
                <Star
                  size={16}
                  className="text-indigo-500 animate-pulse"
                  style={{
                    animationDelay: `${i * 300}ms`,
                    animationDuration: '2s'
                  }}
                />
                <div
                  className="w-2 h-2 rounded-full bg-gradient-to-r from-purple-500 to-blue-500 animate-ping"
                  style={{
                    animationDelay: `${i * 200}ms`,
                    animationDuration: '2s'
                  }}
                />
              </div>
            ))}
          </div>
        </div>
      </div>

      <style>{`
        @keyframes spin-slow {
          from {
            transform: rotate(0deg);
          }
          to {
            transform: rotate(360deg);
          }
        }
        
        .animate-spin-slow {
          animation: spin-slow 4s linear infinite;
        }
      `}</style>
    </div>
  );
};

export default YearBanner;